let loaded = false;

function handleClick(e)
{
	if (e.target?.classList.contains('bvi-open') && !loaded)
	{
		e.preventDefault();
		loaded = true;

		BX.Runtime.loadExtension('redsign.university.bvi')
			.then(() => {
				new Bvi();
				document.removeEventListener('click', handleClick);

				setTimeout(() => {
					e.target.click();
				});
			});
	}
}

document.addEventListener('DOMContentLoaded', () => {
	if ('Bvi' in window)
	{
		new Bvi();
	}
	else
	{
		document.addEventListener('click', handleClick)
	}
});