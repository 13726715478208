import EventHandler from "bootstrap/js/src/dom/event-handler";

const EVENT_PREFIX = 'gos.offcanvas.';

class DeferredOffcanvas extends BX.Event.EventEmitter
{
	static get events()
	{
		return Object.freeze({
			show: `show`,
			shown: `shown`,
			hide: 'hide',
			hidden: `hidden`
		});
	}

	constructor(options = {})
	{
		super();
		this.setEventNamespace(EVENT_PREFIX);

		this.options = {
			url: '/',
			method: 'GET',
			data: {},
			type: 'start',
			cssClass: false,
			closeButton: false,
			title: null,
			titleCssClass: '',
			...options
		};

		this.spinner = new gov.utils.Spinner({ overlay: false });
		this.scrollBarHelper = new gov.utils.ScrollBarHelper();
		this.backdrop = new gov.utils.Backdrop({
			className: 'offcanvas-backdrop',
			isAnimated: true,
			rootElement: document.body,
			clickCallback: () => this.close()
		});

		this.isLoaded = false;

		this.initOffcanvas();
	}

	initOffcanvas()
	{
		const { type, cssClass } = this.options;

		this.offcanvasHeader = this.createHeader();
		this.offcanvasBody = this.createBody();

		this.offcanvas = BX.Tag.render`
			<div class="offcanvas offcanvas-${type} w-auto ${cssClass}" tabindex="-1">
				${this.offcanvasHeader || ''}
				${this.offcanvasBody}
			</div>
		`;

		BX.Dom.append(
			this.offcanvas,
			document.body
		);

		BX.ZIndexManager.register(this.offcanvas, {});

		this.offcanvas.addEventListener('show.bs.offcanvas', this.onShowOffcanvas.bind(this));
		this.offcanvas.addEventListener('shown.bs.offcanvas', this.onShownOffcanvas.bind(this));
		this.offcanvas.addEventListener('hide.bs.offcanvas', this.onHideOffcanvas.bind(this));
		this.offcanvas.addEventListener('hidden.bs.offcanvas', this.onHiddenOffcanvas.bind(this));

		this.bsOffcanvas = new bootstrap.Offcanvas(this.offcanvas, {
			backdrop: false
		});
	}

	createHeader()
	{
		if (!this.options.title && !this.options.closeButton)
			return null;

		const node = BX.Tag.render`<div class="offcanvas-header"></div>`;

		if (this.options.title)
		{
			BX.Dom.append(
				BX.Tag.render`<h5 class="offcanvas-title ${ this.options.titleCssClass }">${ this.options.title }</h5>`,
				node
			);
		}

		if (this.options.closeButton)
		{
			BX.Dom.append(
				BX.Tag.render`
					<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="close">
						<svg class="icon-svg">
							<use xlink:href="` + (BX.message.SITE_DIR || '/') + `include/sprite.svg#close"></use>
						</svg>
					</button>
				`,
				node
			)
		}

		return node;
	}

	updateHeader()
	{
		if (BX.Type.isDomNode(this.offcanvasHeader))
		{
			BX.Dom.replace(
				this.offcanvasHeader,
				this.createHeader()
			);
		}
		else
		{
			BX.Dom.prepend(
				this.createHeader(),
				this.offcanvas,
			)
		}

		this.offcanvasHeader = this.offcanvas.querySelector('.offcanvas-header');
	}

	createBody()
	{
		return BX.Tag.render`<div class="offcanvas-body"></div>`;
	}

	async loadContent()
	{
		const { url, method, data } = this.options;

		const content = BX.Tag.render`<div class="offcanvas-body"></div>`;
		BX.Dom.replace(this.offcanvasBody, content);
		this.offcanvasBody = content;
		const { additionalParams } = await gov.utils.ajax.insertToNode(content, url, method, data);
		this.setOptions(additionalParams || {});

		this.isLoaded = true;
	}

	setOptions(options = {})
	{
		const currentOptions = { ...this.options };
		this.options = { ...this.options, ...options };

		if (
			this.options.title !=currentOptions.title
			|| this.options.closeButton != currentOptions.closeButton
		)
		{
			this.updateHeader();
		}

		if (this.options.cssClass != currentOptions.cssClass)
		{
			BX.Dom.removeClass(this.offcanvas, currentOptions.cssClass);
			BX.Dom.addClass(this.offcanvas, this.options.cssClass);
		}
	}

	async open()
	{
		this.scrollBarHelper.hide();
		this.backdrop.show();

		if (!this.isLoaded)
		{
			this.spinner.setOptions({
				zIndexOptions: {
					overlay: this.backdrop._getElement()
				}
			});

			try
			{
				await this.spinner.start();
			}
			catch (e)
			{
				console.warn(e);
			}

			BX.ZIndexManager.bringToFront(this.offcanvas);
			await this.loadContent();
		}
		else
		{
			BX.ZIndexManager.bringToFront(this.offcanvas);
		}

		this.bsOffcanvas.show();
	}

	close()
	{
		if (this.bsOffcanvas)
		{
			this.bsOffcanvas.hide();
		}
	}

	destroy()
	{
		if (this.bsOffcanvas)
		{
			this.bsOffcanvas.dispose();
			this.offcanvas.remove();
			this.offcanvas = null;
			this.bsOffcanvas = null;
		}
	}

	onShowOffcanvas()
	{
		this.spinner.end();
		this.emit(DeferredOffcanvas.events.show, { data: { instance: this } });
	}

	onShownOffcanvas()
	{
		// Fix admin borders
		if (BX.admin?.__borders)
		{
			this.__zIndex = window.getComputedStyle(BX.admin.__borders.top).zIndex;

			BX.admin.__borders.cont.style.zIndex = 9999;
			BX.admin.__borders.top.style.zIndex = 9999;
			BX.admin.__borders.right.style.zIndex = 9999;
			BX.admin.__borders.bottom.style.zIndex = 9999;
			BX.admin.__borders.left.style.zIndex = 9999;
		}

		this.emit(DeferredOffcanvas.events.shown, { data: { instance: this } });
	}

	onHideOffcanvas()
	{
		this.emit(DeferredOffcanvas.events.hide, { data: { instance: this } });
	}

	onHiddenOffcanvas()
	{
		this.scrollBarHelper.reset();
		this.backdrop.hide();

		// Fix admin borders
		if (BX.admin?.__borders && this.__zIndex)
		{
			BX.admin.__borders.cont.style.zIndex = 'auto';
			BX.admin.__borders.top.style.zIndex = this.__zIndex;
			BX.admin.__borders.right.style.zIndex = this.__zIndex;
			BX.admin.__borders.bottom.style.zIndex = this.__zIndex;
			BX.admin.__borders.left.style.zIndex = this.__zIndex;

			delete this.__zIndex;
		}

		this.emit(DeferredOffcanvas.events.hidden, { data: { instance: this } });
	}
}

EventHandler.on(document, 'click', '[data-trigger="deferred-offcanvas"]', e => {
	e.preventDefault();

	BX.Runtime.loadExtension('main.popup').then(() => {
		const url = e.target?.getAttribute('href') || e.target?.getAttribute('data-src');
		if (!url || url.includes('#') || url.startsWith('.'))
			return null;

		const strOptions = e.target?.getAttribute('data-options') || '';
		const options = BX.parseJSON(strOptions);
		const deferredOffcanvas = new DeferredOffcanvas({...options, url});
		deferredOffcanvas.open();

		deferredOffcanvas.subscribe(DeferredOffcanvas.events.hidden, () => {
			deferredOffcanvas.destroy();
		});
	});
});

export default DeferredOffcanvas;