
import Collapse from 'bootstrap/js/src/collapse';
import Dropdown from 'bootstrap/js/src/dropdown';
import Tab from 'bootstrap/js/src/tab';
import Offcanvas from 'bootstrap/js/src/offcanvas';
import Popover from 'bootstrap/js/src/popover';

window.bootstrap = {
	...window.bootstrap || {},
	Collapse,
	Dropdown,
	Tab,
	Offcanvas,
	Popover
};
