import './styles/_index.scss';
import './styles/_editor.scss';

import './plugins/bootstrap/bootstrap';
import './plugins/bvi/index';

import DeferredOffcanvas from './components/deferred_offcanvas';

const components = {
	...(window.gov?.components || {}),
	DeferredOffcanvas
};

export {
	components
};